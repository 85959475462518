
import { Button } from "@mui/material";
//import { ApiError } from "@supabase/supabase-js";
import { useState } from "react";
import { supabase } from "./supabaseClient";
import { Box } from "@mui/system";
import logoImage from './components/images/uvc_white_logo_2.png';


const URL = process.env.VERCEL_URL ;
//const URL = "http://localhost:3000";


// 'https://uvc-hub.vercel.app'

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleAzureLogin = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "azure",
        options: {

          scopes: "email openid", // TODO Add in any additional azure scopes
          redirectTo: URL,
        },
      });
      // if (error)
      //   throw error;
    } catch (error: any) {
      debugger;
      alert(error?.error_description || error?.message);
    } finally {
      debugger;
      setLoading(false);
    }
  };

  return (
    <Box sx={{ margin: 10 }}>
      <Button variant="contained" size="medium" endIcon={<img src={logoImage} style={{
        height: "55px",
        margin: "4px auto",
        }}/>}
        onClick={(e) => {
          e.preventDefault();
          handleAzureLogin();
        }}
      >
        {loading ? "Loading..." : "Login with" }
      </Button>
    </Box>
  );
}
